import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Screens.css";

function WelcomeScreen() {
    const [nickname, setNickname] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedOpponent, setSelectedOpponent] = useState("");
    const [isFileUploaded, setIsFileUploaded] = useState(false);
    const navigate = useNavigate();
    const [gameMode, setGameMode] = useState("normal");
    const [classroom, setClassroom] = useState(""); // Add this new state
    const [showModes, setShowModes] = useState(false);

    const expandModes = () => {
        setShowModes(true);
    };

    const handlePracticeClick = () => {
        navigate("/test", {
            state: {
                nickname,
                classroom,
            },
        });
    };

    const handleFightClick = () => {
        navigate("/first", {
            state: {
                nickname,
                classroom,
            },
        });
    };

    // const handleStart = async () => {
    //     if (nickname.trim() && isFileUploaded && selectedOpponent) {
    //         try {
    //             console.log("Starting game with:", {
    //                 opponent: selectedOpponent,
    //                 nickname,
    //             });

    //             const response = await fetch("/api/start-game", {
    //                 method: "POST",
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                 },
    //                 body: JSON.stringify({
    //                     opponent: selectedOpponent,
    //                     nickname: nickname,
    //                 }),
    //             });

    //             if (response.ok) {
    //                 const data = await response.json();
    //                 console.log("Game response:", data);
    //                 if (data.movesFileReady) {
    //                     navigate("/second", {
    //                         state: {
    //                             nickname,
    //                             opponent: selectedOpponent,
    //                         },
    //                     });
    //                 }
    //             } else {
    //                 throw new Error("Game initialization failed");
    //             }
    //         } catch (error) {
    //             console.error("Error starting game:", error);
    //             alert("Failed to start game");
    //         }
    //     } else {
    //         alert("Please fill in all fields!");
    //     }
    // };

    // const handleFileChange = async (event) => {
    //     const file = event.target.files[0];
    //     if (file && file.name.endsWith(".cpp")) {
    //         try {
    //             console.log("Starting file upload process");
    //             setSelectedFile(file);

    //             const formData = new FormData();
    //             formData.append("nickname", nickname);
    //             formData.append("file", file);
    //             formData.append("opponent", selectedOpponent);

    //             console.log("Sending request to server...", {
    //                 fileName: file.name,
    //                 size: file.size,
    //                 nickname: nickname,
    //             });

    //             const response = await fetch("/api/uploads", {
    //                 method: "POST",
    //                 body: formData,
    //             });

    //             console.log("Received response:", {
    //                 status: response.status,
    //                 statusText: response.statusText,
    //             });

    //             const data = await response.json();
    //             console.log("Response data:", data);

    //             if (response.ok) {
    //                 console.log("Upload successful");
    //                 setIsFileUploaded(true);
    //             } else {
    //                 throw new Error(data.error || "Upload failed");
    //             }
    //         } catch (error) {
    //             console.error("Error during upload:", error);
    //             setIsFileUploaded(false);
    //             alert(`Failed to upload file: ${error.message}`);
    //         }
    //     } else {
    //         alert("Please select a .cpp file");
    //         event.target.value = "";
    //         setSelectedFile(null);
    //         setIsFileUploaded(false);
    //     }
    // };

    return (
        <div>
            <div
                style={{
                    marginTop: "100px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "flex-start",
                        gap: "10px",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center", // This ensures vertical alignment
                        }}
                    >
                        <label
                            htmlFor="nickname"
                            style={{ fontFamily: "monospace" }}
                        >
                            nazwa gracza:
                        </label>
                        <input
                            id="nickname"
                            type="text"
                            value={nickname}
                            onChange={(e) => setNickname(e.target.value)}
                            style={{ width: "200px", marginLeft: "10px" }}
                        />
                    </div>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            marginLeft: "10px",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                height: "22px", // Match the height of the other input
                            }}
                        >
                            <label
                                htmlFor="kl"
                                style={{ fontFamily: "monospace" }}
                            >
                                klasa:
                            </label>
                            <input
                                id="klasa"
                                type="text"
                                value={classroom}
                                onChange={(e) => setClassroom(e.target.value)}
                                style={{ width: "30px", marginLeft: "5px" }}
                            />
                        </div>
                        <span
                            style={{
                                marginTop: "5px",
                                fontSize: "10px",
                                color: "#666",
                                fontFamily: "monospace",
                            }}
                        >
                            (opcjonalnie)
                        </span>
                    </div>
                </div>

                {!showModes && (
                    <div style={{ marginTop: "20px" }}>
                        <button
                            onClick={expandModes}
                            style={{
                                padding: "5px 10px",
                                fontFamily: "monospace",
                                cursor: "pointer",
                            }}
                        >
                            rozpocznij
                        </button>
                    </div>
                )}

                {showModes && (
                    <div
                        style={{
                            marginTop: "40px",
                            display: "flex",
                            width: "80%",
                            maxWidth: "800px",
                            gap: "20px",
                        }}
                    >
                        <div
                            style={{
                                flex: 1,
                                textAlign: "center",
                                padding: "20px",
                                border: "1px solid #ccc",
                                cursor: "pointer",
                                transition: "transform 0.2s",
                                ":hover": {
                                    transform: "scale(1.02)",
                                },
                            }}
                            onClick={handlePracticeClick}
                        >
                            <h2
                                style={{
                                    fontSize: "24px",
                                    fontFamily: "monospace",
                                    marginBottom: "15px",
                                }}
                            >
                                TRENUJ
                            </h2>
                            <p
                                style={{
                                    fontSize: "14px",
                                    fontFamily: "monospace",
                                    color: "#666",
                                }}
                            >
                                naucz się, jak napisać swój pierwszy program
                                grający w gomoku, a potem spróbuj go
                                udoskonalić!
                            </p>
                        </div>

                        <div
                            style={{
                                flex: 1,
                                textAlign: "center",
                                padding: "20px",
                                border: "1px solid #ccc",
                                cursor: "pointer",
                                transition: "transform 0.2s",
                                ":hover": {
                                    transform: "scale(1.02)",
                                },
                            }}
                            onClick={handleFightClick}
                        >
                            <h2
                                style={{
                                    fontSize: "24px",
                                    fontFamily: "monospace",
                                    marginBottom: "15px",
                                }}
                            >
                                WALCZ
                            </h2>
                            <p
                                style={{
                                    fontSize: "14px",
                                    fontFamily: "monospace",
                                    color: "#666",
                                }}
                            >
                                weź udział w zawodach zgłaszając swój program,
                                który zagra przeciwko botom innych graczy!
                            </p>
                        </div>
                    </div>
                )}

                {/* <div style={{ marginTop: "20px" }}>
                    <button
                        onClick={expandModes}
                        style={{
                            padding: "5px 10px",
                            fontFamily: "monospace",
                            cursor: "pointer",
                        }}
                    >
                        rozpocznij
                    </button>
                </div> */}
            </div>

            {/*
            <div style={{ marginTop: "10px" }}>
                <input
                    type="file"
                    id="file"
                    accept=".cpp"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                />
                <button
                    onClick={() => document.getElementById("file").click()}
                    style={{
                        padding: "5px 10px",
                        fontFamily: "monospace",
                        cursor: "pointer",
                    }}
                >
                    upload your code
                </button>
                {selectedFile && (
                    <span
                        style={{
                            marginLeft: "10px",
                            fontFamily: "monospace",
                        }}
                    >
                        {selectedFile.name}
                    </span>
                )}
            </div>
            */}

            {/* {isFileUploaded && (
                <div style={{ marginTop: "20px" }}>
                    <label
                        htmlFor="opponent"
                        style={{ marginRight: "10px", fontFamily: "monospace" }}
                    >
                        select opponent:
                    </label>
                    <select
                        id="opponent"
                        value={selectedOpponent}
                        onChange={(e) => setSelectedOpponent(e.target.value)}
                        style={{ fontFamily: "monospace" }}
                    >
                        <option value="">choose...</option>
                        <option value="admin">admin</option>
                        <option value="random">random</option>
                    </select>
                </div>
            )}

            {isFileUploaded && (
                <div style={{ marginTop: "20px" }}>
                    <label
                        style={{ marginRight: "10px", fontFamily: "monospace" }}
                    >
                        mode:
                    </label>
                    <select
                        value={gameMode}
                        onChange={(e) => setGameMode(e.target.value)}
                        style={{ fontFamily: "monospace" }}
                    >
                        <option value="normal">normal</option>
                        <option value="test">test</option>
                    </select>
                </div>
            )}

            {isFileUploaded && selectedOpponent && (
                <button
                    onClick={() => {
                        if (gameMode === "test") {
                            navigate("/test", {
                                state: {
                                    nickname,
                                    opponent: selectedOpponent,
                                },
                            });
                        } else {
                            handleStart();
                        }
                    }}
                    style={{
                        padding: "5px 10px",
                        fontFamily: "monospace",
                        marginTop: "20px",
                    }}
                >
                    start
                </button>
            )} */}
        </div>
    );
}

export default WelcomeScreen;
