import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Board from "./Board";
import "./Screens.css";

function FirstScreen() {
    const location = useLocation();
    const navigate = useNavigate();
    const { nickname } = location.state || {};
    const [opponents, setOpponents] = useState([]);
    const [selectedOpponent, setSelectedOpponent] = useState("");
    const [board, setBoard] = useState(
        Array(10)
            .fill()
            .map(() => Array(10).fill(" ")),
    );
    const [gameStarted, setGameStarted] = useState(false);
    const [winner, setWinner] = useState(null);
    const [gameEnded, setGameEnded] = useState(false);
    const [winningCells, setWinningCells] = useState([]);

    // Fetch available opponents when component mounts
    useEffect(() => {
        fetch("/api/get-opponents")
            .then((response) => response.json())
            .then((data) => {
                setOpponents(data.opponents);
            })
            .catch((error) =>
                console.error("Error fetching opponents:", error),
            );
    }, []);

    const handlePlayClick = async () => {
        if (!selectedOpponent) return;

        try {
            setGameStarted(true);
            // Reset all states at the start
            setWinner(null);
            setGameEnded(false);
            setWinningCells([]);
            setBoard(
                Array(10)
                    .fill()
                    .map(() => Array(10).fill(" ")),
            );

            const response = await fetch("/api/start-bot-game", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    player1: nickname,
                    player2: selectedOpponent,
                }),
            });

            const data = await response.json();
            console.log("Game data received:", data);

            if (data.success && data.moves && data.moves.length > 0) {
                // Process moves one by one
                let currentBoard = Array(10)
                    .fill()
                    .map(() => Array(10).fill(" "));

                for (const move of data.moves) {
                    // Update the working copy of the board
                    currentBoard = currentBoard.map((row) => [...row]);
                    currentBoard[move.x][move.y] = move.symbol;

                    // Update the state with the new board
                    setBoard([...currentBoard]);

                    // Check for winner
                    if (move.winner) {
                        setWinner(move.symbol);
                        if (move.winning_cells) {
                            setWinningCells(move.winning_cells);
                        }
                        setGameEnded(true);
                    }

                    // Wait before processing next move
                    await new Promise((resolve) => setTimeout(resolve, 500));
                }
            }
        } catch (error) {
            console.error("Error playing game:", error);
        } finally {
            setGameStarted(false);
        }
    };

    const handlePlayAgain = () => {
        setBoard(
            Array(10)
                .fill()
                .map(() => Array(10).fill(" ")),
        );
        setGameStarted(false);
        setWinner(null);
        setGameEnded(false);
        setWinningCells([]);
    };

    return (
        <div style={{ padding: "20px" }}>
            {/* Header section */}
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "20px",
                }}
            >
                {/* Player name */}
                <div style={{ fontFamily: "monospace", fontSize: "18px" }}>
                    {nickname}
                </div>

                {/* Opponent selector */}
                <div>
                    <select
                        value={selectedOpponent}
                        onChange={(e) => setSelectedOpponent(e.target.value)}
                        style={{
                            padding: "5px 10px",
                            fontFamily: "monospace",
                            cursor: "pointer",
                        }}
                    >
                        <option value="">wybierz przeciwnika...</option>
                        {opponents.map((opponent) => (
                            <option key={opponent} value={opponent}>
                                {opponent}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            {/* Game board */}
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Board
                    board={board}
                    interactive={true}
                    winningCells={winningCells}
                />
            </div>

            {/* Controls */}
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                    gap: "10px",
                }}
            >
                <button
                    onClick={handlePlayClick}
                    disabled={!selectedOpponent || gameStarted}
                    style={{
                        padding: "10px 20px",
                        fontFamily: "monospace",
                        cursor:
                            !selectedOpponent || gameStarted
                                ? "not-allowed"
                                : "pointer",
                        opacity: !selectedOpponent || gameStarted ? 0.5 : 1,
                    }}
                >
                    {gameStarted ? "trwa gra..." : "graj"}
                </button>

                {gameEnded && (
                    <button
                        onClick={handlePlayAgain}
                        style={{
                            padding: "10px 20px",
                            fontFamily: "monospace",
                            cursor: "pointer",
                        }}
                    >
                        rewanż
                    </button>
                )}
            </div>

            {/* Winner announcement */}
            {winner && (
                <div
                    style={{
                        textAlign: "center",
                        marginTop: "20px",
                        fontFamily: "monospace",
                        fontSize: "18px",
                        color: winner === "O" ? "#4CAF50" : "#f44336",
                    }}
                >
                    {winner === "O" ? "wygrałeś" : "przegrałeś"}
                </div>
            )}

            {/* Back button */}
            <div
                style={{
                    position: "fixed",
                    bottom: "20px",
                    left: "20px",
                }}
            >
                <button
                    onClick={() => navigate("/")}
                    style={{
                        padding: "5px 10px",
                        fontFamily: "monospace",
                        cursor: "pointer",
                        // backgroundColor: "#f0f0f0",
                        // border: "1px solid #ccc",
                        // borderRadius: "4px",
                    }}
                >
                    powrót
                </button>
            </div>
        </div>
    );
}

export default FirstScreen;
