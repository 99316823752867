import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Board from "./Board";
import CodeEditor from "./CodeEditor";

function TestMode() {
    const location = useLocation();
    const navigate = useNavigate();
    const [board, setBoard] = useState(
        Array(10)
            .fill()
            .map(() => Array(10).fill(" ")),
    );
    const [gameId, setGameId] = useState(null);
    const [isPlayerTurn, setIsPlayerTurn] = useState(true);
    const { nickname, opponent } = location.state || {};
    const [winner, setWinner] = useState(null);
    const [gameEnded, setGameEnded] = useState(false);
    const [winningCells, setWinningCells] = useState([]);
    const [code, setCode] = useState(null);
    const [isCompiling, setIsCompiling] = useState(false);
    const [compilationError, setCompilationError] = useState(null);
    const [isCompiled, setIsCompiled] = useState(false);

    const handleCodeChange = (newCode) => {
        setCode(newCode);
    };

    const handleCompileClick = async () => {
        setIsCompiling(true);
        setCompilationError(null);

        try {
            const response = await fetch("/api/compile-code", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    nickname,
                    code,
                }),
            });

            const data = await response.json();

            if (response.ok) {
                setIsCompiled(true);
                console.log("Compilation successful");
            } else {
                throw new Error(data.error || "Compilation failed");
            }
        } catch (error) {
            console.error("Compilation error:", error);
            setCompilationError(error.message);
            setIsCompiled(false);
        } finally {
            setIsCompiling(false);
        }
    };

    const handlePlayClick = async () => {
        if (!isCompiled) return;

        try {
            const response = await fetch("/api/start-interactive-game", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    nickname,
                }),
            });

            const data = await response.json();
            if (!data.success) {
                throw new Error(data.error || "Failed to start game");
            }

            setGameId(data.gameId);
            setBoard(
                Array(10)
                    .fill()
                    .map(() => Array(10).fill(" ")),
            );
            setIsPlayerTurn(true);
            setWinner(null);
            setGameEnded(false);
            setWinningCells([]);
        } catch (error) {
            console.error("Error starting game:", error);
            setCompilationError(error.message || "Failed to start the game");
        }
    };

    useEffect(() => {
        if (nickname) {
            fetch(`/api/get-code/${nickname}`)
                .then((response) => response.json())
                .then((data) => {
                    if (data.code) {
                        setCode(data.code);
                    }
                })
                .catch((error) => console.error("Error loading code:", error));
        }
    }, [nickname]);

    const handleCellClick = async (x, y) => {
        if (!isPlayerTurn || board[x][y] !== " " || !gameId || gameEnded)
            return;

        setIsPlayerTurn(false);

        try {
            const controller = new AbortController();
            const timeoutId = setTimeout(() => controller.abort(), 6000); // 6 second timeout

            const response = await fetch("/api/make-move", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ gameId, x, y }),
                signal: controller.signal,
            });

            clearTimeout(timeoutId);

            const botMove = await response.json();

            if (botMove.error) {
                console.error("Move error:", botMove.error);
                // Reset game state
                setIsPlayerTurn(true);
                if (botMove.error === "Move timeout") {
                    alert("Game timed out. Please start a new game.");
                    navigate("/welcome");
                }
                return;
            }

            setBoard((prev) => {
                const newBoard = prev.map((row) => [...row]);
                newBoard[x][y] = "O";
                if (botMove.x >= 0 && botMove.y >= 0) {
                    newBoard[botMove.x][botMove.y] = "X";
                }
                return newBoard;
            });

            if (botMove.winner) {
                setWinner(botMove.winner);
                setGameEnded(true);
                if (botMove.winning_cells) {
                    setWinningCells(botMove.winning_cells);
                }
                await fetch("/api/end-game", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ gameId }),
                });
            } else {
                setIsPlayerTurn(true);
            }
        } catch (error) {
            console.error("Error making move:", error);
            if (error.name === "AbortError") {
                alert("Move timed out. Please try again or start a new game.");
            }
            // Reset game state
            setIsPlayerTurn(true);
        }
    };

    return (
        <div>
            <h2 style={{ textAlign: "center", fontFamily: "monospace" }}>
                napisz swojego bota
            </h2>
            <div
                style={{
                    display: "flex",
                    gap: "20px",
                    padding: "20px",
                }}
            >
                {/* Left side: Game Board */}
                <div style={{ flex: 1 }}>
                    <h3
                        style={{ textAlign: "center", fontFamily: "monospace" }}
                    >
                        {/* playing against {opponent} */}
                    </h3>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-around",
                            marginBottom: "20px",
                        }}
                    >
                        <div style={{ fontFamily: "monospace" }}>Ty (O)</div>
                        <div style={{ fontFamily: "monospace" }}>
                            Twój program (X)
                        </div>
                    </div>
                    {/* {winner && (
                        <div
                            style={{
                                textAlign: "center",
                                fontFamily: "monospace",
                                fontSize: "1.2em",
                                fontWeight: "bold",
                                color: winner === "O" ? "#4CAF50" : "#f44336",
                                marginBottom: "20px",
                            }}
                        >
                            {winner === "O"
                                ? "wygrałeś z botem"
                                : "bot wygrał!"}
                        </div>
                    )} */}
                    <Board
                        board={board}
                        onCellClick={handleCellClick}
                        interactive={true}
                        winningCells={winningCells}
                    />
                    {!isPlayerTurn && !gameEnded && (
                        <div
                            style={{
                                textAlign: "center",
                                fontFamily: "monospace",
                                marginTop: "20px",
                            }}
                        >
                            oczekiwanie na ruch bota...
                        </div>
                    )}
                </div>

                {/* Right side: Code Editor */}
                {/* <div style={{ flex: 1 }}>
                    <CodeEditor code={code} onChange={handleCodeChange} />
                </div> */}

                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <CodeEditor code={code} onChange={handleCodeChange} />
                    {/* <div style={{ marginTop: "20px", textAlign: "center" }}>
                        <button
                            onClick={handlePlayClick}
                            disabled={isCompiling || !gameId}
                            style={{
                                padding: "10px 20px",
                                fontFamily: "monospace",
                                cursor: isCompiling ? "wait" : "pointer",
                            }}
                        >
                            {isCompiling ? "Compiling..." : "Play"}
                        </button>

                        {compilationError && (
                            <div
                                style={{
                                    color: "red",
                                    marginTop: "10px",
                                    fontFamily: "monospace",
                                }}
                            >
                                {compilationError}
                            </div>
                        )}

                        {gameEnded && (
                            <button
                                onClick={() => navigate("/welcome")}
                                style={{
                                    // display: "block",
                                    margin: "20px auto",
                                    padding: "10px 20px",
                                    fontFamily: "monospace",
                                    cursor: "pointer",
                                }}
                            >
                                play again
                            </button>
                        )}
                    </div> */}
                    <div>
                        <button
                            onClick={handleCompileClick}
                            disabled={isCompiling}
                            style={{
                                padding: "5px 10px",
                                fontFamily: "monospace",
                                cursor: isCompiling ? "wait" : "pointer",
                                marginRight: "10px",
                            }}
                        >
                            {isCompiling ? "kompilacja..." : "zapisz"}
                        </button>

                        <button
                            onClick={handlePlayClick}
                            disabled={!isCompiled}
                            style={{
                                padding: "5px 10px",
                                fontFamily: "monospace",
                                cursor: !isCompiled ? "not-allowed" : "pointer",
                            }}
                        >
                            graj
                        </button>
                    </div>
                </div>
            </div>
            <div
                style={{
                    position: "fixed",
                    bottom: "20px",
                    left: "20px",
                }}
            >
                <button
                    onClick={() => navigate("/welcome")}
                    style={{
                        padding: "5px 10px",
                        fontFamily: "monospace",
                        cursor: "pointer",
                        // backgroundColor: "#f0f0f0",
                        // border: "1px solid #ccc",
                        // borderRadius: "4px",
                    }}
                >
                    powrót
                </button>
            </div>
        </div>
    );
}

export default TestMode;
