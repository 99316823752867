import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import WelcomeScreen from "./WelcomeScreen";
import SecondScreen from "./SecondScreen";
import Game from "./Game";
import TestMode from "./TestMode";
import FirstScreen from "./FirstScreen";

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Game />} />
                <Route path="/welcome" element={<WelcomeScreen />} />
                <Route path="/second" element={<SecondScreen />} />
                <Route path="/test" element={<TestMode />} />
                <Route path="/first" element={<FirstScreen />} />
            </Routes>
        </Router>
    );
}

export default App;
